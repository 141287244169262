import React, { useEffect, useState } from 'react';
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { getTopper } from '../Service/Api';
import LazyLoad from 'react-lazyload';

const ToppersXII = () => {
    var settings = {
        autoplay: true,
        infinite: true,
        autoplaySpeed: 2000,
        speed: 1000,
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 0,   
        arrows:false,
        adaptiveHeight: true,
        responsive: [
            {
              breakpoint: 1400,
              settings: {
                slidesToShow: 1, 
              }
            },
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 1, 
              }
            },
            {
              breakpoint: 767,
              settings: {
                slidesToShow:1,  
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1 
              }
            }
          ]
    }
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const topperData = await getTopper();
                setData(topperData);
            } catch (error) {
                console.error("Error fetching topper data:", error);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        console.log("topper_data", data);
    }, [data]);

    const emptyArray = [
        { name: "Student Name", stream: "Stream", division: "100%", attachments: "https://webapi.entab.info/api/image/STHSM/public/Images/trophy.png" },   
        { name: "Student Name", stream: "Stream", division: "100%", attachments: "https://webapi.entab.info/api/image/STHSM/public/Images/trophy.png" },   
        { name: "Student Name", stream: "Stream", division: "100%", attachments: "https://webapi.entab.info/api/image/STHSM/public/Images/trophy.png" },   
      ];

      

    return (
        <div className="tpr-block">
            <div className='tpr-class-img'>
                <LazyLoad><img src="https://webapi.entab.info/api/image/STHSM/public/Images/12.png" alt="Topper Class" /></LazyLoad>
            </div>
            <Slider {...settings}>
                {data.length > 0 ?
                    data.filter(item => item.class === "XII").map((item, index) => (
                        <div className="item" key={index}>
                            <div className='topper-img'>
                                <LazyLoad><img src={`https://webapi.entab.info/api/image/${item.attachments}`} alt="Topper" /></LazyLoad>
                            </div>
                            <div className='topper-cont'>
                                <p className='percent'>{item.division}</p>
                                <p>{item.name}</p>
                                <p>{item.stream}</p>
                            </div>
                        </div>
                    ))
                    :
                    emptyArray.map((data, index) => (
                        <div className="item" key={index}>
                            <div className='topper-img'>
                                <LazyLoad><img src={data.attachments} alt="Default Topper" /></LazyLoad>
                            </div>
                            <div className='topper-cont'>
                                <p className='percent'>{data.division}</p>
                                <p>{data.name}</p>
                                <p>{data.stream}</p>
                            </div>
                        </div>
                    ))
                }
            </Slider>
        </div>
    );
};

export default ToppersXII;
