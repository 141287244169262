import React, { useEffect, useState } from "react"; 
import Header from '../components/Header'
import Breadcrumb from "../components/Breadcrumb";
import Footer from '../components/Footer'; 
import { getTopper } from "../Service/Api"; // Ensure getTopper is correctly imported
import LazyLoad from "react-lazyload";

const Toppers = () => { 
  const [data, setData] = useState([]);
  const [selectedYear, setSelectedYear] = useState(""); 
  const [yearRanges, setYearRanges] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const lastYear = currentYear - 1;
    const yearRange = `${lastYear}-${currentYear}`;
    setSelectedYear(yearRange);
    fetchData(yearRange); // Try fetching data for the current academic year
  }, []);

  const fetchData = async (year) => {
    try {
      const toppersData = await getTopper();
      const filteredData = toppersData.filter(
        (item) => item.sessionYear === year && item.class === "X"
      );

      if (filteredData.length > 0) {
        setData(filteredData); // Set data for the current year
      } else {
        // If no data for current year, fetch for last year
        const [startYear] = year.split("-");
        const fallbackYear = `${startYear - 1}-${startYear}`;
        const fallbackData = toppersData.filter(
          (item) => item.sessionYear === fallbackYear && item.class === "X"
        );
        setData(fallbackData);
        setSelectedYear(fallbackYear); // Update selected year to fallback year
      }

      // Populate year ranges for the dropdown
      const years = new Set(toppersData.map((item) => item.sessionYear));
      setYearRanges([...years]);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleYearChange = (e) => {
    const year = e.target.value;
    setSelectedYear(year);
    fetchData(year); // Fetch data for the selected year
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };
  return (
    <>
    <Header/>
    <Breadcrumb pageTitle="Toppers ICSE" mid="About" breadcrumbName="Toppers ICSE" />
    <div className="inner-page">
    <div className='container'>
          <div className="row tabs-dynamic">
            <div className="col-md-6">
              <div className="month-selection">
                <select
                  value={selectedYear}
                  onChange={(e) => setSelectedYear(e.target.value)}
                  className="yearSelection">
                  <option value={"All"}>All</option>
                  {yearRanges.map((yr) => (
                    <option key={yr} value={yr}>
                      {yr}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div className="searchBox">
                <input
                  type="text"
                  id="myInput"
                  name="name"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  placeholder="Search Here.."
                />
              </div>
            </div>
          </div>
          <div className="row">
            {data.length > 0 &&
              data.map((item, index) => (
                <div className="col-xl-2 col-lg-3 col-md-4 col-xs-6" key={index}>
                  <div className="toppers-inner">
                    <LazyLoad
                      offset={10}
                      placeholder={
                        <img
                          src="https://webapi.entab.info/api/image/STHSM/public/Images/trophy.png"
                          alt="Loading..."
                          className="img-fluid"
                        />
                      }>
                      <img
                        src={
                          item.attachments && item.attachments[0]
                            ? `https://webapi.entab.info/api/image/${item.attachments[0]}`
                            : "/images/trophy.png"
                        }
                        className="img-fluid"
                        alt="STHSM"
                      />
                    </LazyLoad>
                    <h3>{item.name}</h3>
                    <p>{item.stream}</p>
                    <h5>{item.division}</h5>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
      <Footer/>
    </>
  );
};

export default Toppers;
