import axios from "axios"
import { API_URL, getHeader } from "../config"

export const getHighlight = async() => {
    const { data } = await axios.get(API_URL + `/api/highlight/STHSM`);
    console.log(data)
    return data;
} 

export const getNews = async() => {
    console.log("first")
    const { data } = await axios.get(API_URL + `/api/news/STHSM`);
    console.log("news:",data)
    return data;
} 

export const getEvents = async() => {
    const { data } = await axios.get(API_URL + `/api/events/STHSM`);
    console.log("lals events",data)
    return data;
} 

export const getEventsinner = async() => {
    const { data } = await axios.get(API_URL + `/api/events/All/STHSM`);
    console.log(data)
    return data;
}

export const getBirthdays = async() => {
    const { data } = await axios.get(API_URL + `/api/birthdays/STHSM`);
    console.log(data)
    return data;
}

export const getGallery = async() => {
    const { data } = await axios.get(API_URL + `/api/gallery/STHSM`);
    console.log(data)
    return data;
} 

export const getGalleryInner = async() => {
    const { data } = await axios.get(API_URL + `/api/gallery/all/STHSM`);
    return data;
} 

export const getSubGallery = async(id) => {
    console.log(id)
    const { data } = await axios.get(API_URL + `/api/subGallery?id=${id}`);
    console.log(data)
    return data;
} 

export const getNotification = async() => {
    const { data } = await axios.get(API_URL + `/api/notification/STHSM`,getHeader());
    return data;
} 

export const getTopper = async() => {
    const { data } = await axios.get(API_URL + `/api/topper/STHSM`,getHeader());
    return data;
} 

export const getOnlineRegistration = async() => {
    const { data } = await axios.get(API_URL + `/api/onlineRegistration/STHSM`,getHeader());
    return data;
} 

export const getSliders = async() => {
    const { data } = await axios.get(API_URL + `/api/slider/STHSM`,getHeader());
    return data;
} 

export const getPrincipalMessage = async() => {
    const { data } = await axios.get(API_URL + `/api/PrincipalMessage/STHSM`, getHeader());
    return data;
} 

// export const getMedia = async() => {
//     const { data } = await axios.get(API_URL + `/api/media/SHSJ`, getHeader());
//     return data;
// } 

export const getVideo = async() => {
    const { data } = await axios.get(API_URL + `/api/video/all/STHSM`, getHeader());
    return data;
} 
export const postVisitor = async() => {
    const { data } = await axios.post(API_URL + `/api/visitor`, { schoolcode: "STHSM" }, getHeader());
     
    return data;
  }
  export const getVisitor= async() => {
    const { data } = await axios.get(API_URL + `/api/visitor/STHSM`, getHeader());
   
    return data;
  }
  

export const postTransferCertificate = async(name,admission) => {
    console.log(name,admission)

    const { data } = await axios.post(API_URL + `/api/tc/STHSM`,{name,admission}, getHeader());
    return data;
}
