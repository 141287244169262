import { useEffect, useState } from 'react';
import { getPrincipalMessage } from '../Service/Api';
 
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import LazyLoad from 'react-lazyload';
const PrincipalMessage = () => {

    const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  console.log(data);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const principalData = await getPrincipalMessage();
        setData(principalData);
      } catch (error) {
        console.log("Data Error", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);
  var settings = {
    autoplay: true,
    infinite: true,
    autoplaySpeed: 2000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,   
    arrows:false,
    adaptiveHeight: true,
     
}
    
    



    return (
      <Slider {...settings}>
        {data && data.length > 0 ? (
            data.map((item, index) => (
            <div className="item" key={index}>
                <div className="message-block">
                    <div className='message-content animateMe' data-animation="fadeInLeft">
                      <div dangerouslySetInnerHTML={{ __html: item.message }} />
                        <div class="button"><a href="/principal-message">READ MORE</a></div>
                    </div>
                    <div className='message-image animateMe' data-animation="fadeInRight">
                        <LazyLoad><img src={`https://webapi.entab.info/api/image/${item.attachments[0]}`}></img></LazyLoad>
                    </div>
                </div>
            </div>
              ))
              ) : (<></>)
            }
             
             </Slider>
    );
};

export default PrincipalMessage;
