    // import react { useEffect } from 'react'; 
    // import $ from 'jquery'
    import Header from '../components/Header'
    import News from '../components/News'
    import Copyright from '../components/Copyright'
    import SliderComponent from '../components/lightslider'
    import Events from '../components/Events' 
    import PrincipalMessage from '../components/PrincipalMessage' 
    import Birthday from '../components/Birthday' 
    import Topper from '../components/Topper' 
    import Footer from '../components/Footer'  
    import Notification from '../components/Notification'  
    import Gallery from '../components/Gallery'
    import { Link, Navigate } from 'react-router-dom'   
    import ToppersXII from'../components/ToppersXII'
import LazyLoad from 'react-lazyload'
    //import WOW from 'wow.js'; // Import WOW.js library
    const Home = () => {    
      
        
        return(
            <>
            
                <Header></Header>
                <div className='notification'>
                <Notification />
                </div>
                {/* <div className='online-reg'>
                    <img src="https://webapi.entab.info/api/image/STHSM/public/Images/registration.png" />
                </div> */}
                <div className='container-fluid min-ht'>
                    <div className="row">
                    
                        <div className='col-md-12 pd-0 order-lg-12'>
                            <SliderComponent />
                        </div> 
                    </div>
                                
                </div> 
                <div className='vision-mission'>
                    <div class="container">
                        <div className='row'>
                            
                            <div className='col-md-12 vision-mission-col animateMe' data-animation="fadeInRight">
                                <h3>
                                    <LazyLoad><img src='https://webapi.entab.info/api/image/STHSM/public/Images/vision.gif'></img></LazyLoad> Our vision</h3>
                                <p>Be a leading school in India, supplying qualified citizens to run the administrative machinery transparently, to shoulder the developmental tasks of the nation effectively, in scientific, medical, technological and other fields, enabling the development of villages where the heart of India lies.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="about-us">
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-12  animate__animated animate__slideInUp'>
                                <div className="title">
                                    <div class="title-gif">
                                        <LazyLoad><img src='https://webapi.entab.info/api/image/STHSM/public/Images/about.gif'></img></LazyLoad>
                                    </div>
                                        <h3>ABOUT SCHOOL</h3>
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-12 animateMe'  data-animation="fadeInLeft">
                                <LazyLoad><img src="https://webapi.entab.info/api/image/STHSM/public/Images/about-img.png" className='img-fluid' /></LazyLoad>
                            </div>
                            <div className='col-lg-6 col-md-12 animateMe'  data-animation="fadeInRight">
                            <p><span>St. Thomas School, Civil lines, Mainpuri</span> is a Catholic Christian,
minority educational institution, which was started in 1976
imparting education to all students irrespective of their sex, caste,
creed and religion to strengthen the inter religious, communal,
cultural and social ties among the students, so that they learn
to respect all religions and cultures in our country and strive
to maintain unity in diversity as part of God's plan for
humanity. It is an English medium co-educational school, affiliated
to the CISCE, Delhi and is being run by Christu Raj Education
Society, Mainpuri (registered)</p>
                                <div className='button'><Link to="/about-us">READ MORE</Link></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='news-event'>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-lg-4 news-bg animateMe' data-animation="fadeInLeft">
                                <div className='news'>
                                <div className="title">
                                    <div class="title-gif">
                                        <LazyLoad><img src='https://webapi.entab.info/api/image/STHSM/public/Images/news.gif'></img></LazyLoad>
                                    </div>
                                        <h3>LATEST NEWS</h3>
                                </div> 
                                    <div className='news-blk'>
                                        <News />
                                        <div className='button'>
                                       <Link to="/news">VIEW ALL</Link>

                                        

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-8 animateMe' data-animation="fadeInRight">
                                <div className='events'>
                                    <div className='title'>
                                        <div className='title-gif'>
                                            <LazyLoad><img src="https://webapi.entab.info/api/image/STHSM/public/Images/events.gif" /></LazyLoad>
                                        </div>
                                        <h3>Events & Updates</h3>
                                    </div>
                                    <div className='events-blk'>
                                        <Events />
                                        <div className='button'>
                                     <center>  <Link to="/events">VIEW ALL</Link>     </center>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="message">
                    <div className='container-fluid'>
                        <div class="row">
                            <div class="col-md-12">
                                <div className="title">
                                    <div class="title-gif">
                                        <LazyLoad><img src='https://webapi.entab.info/api/image/STHSM/public/Images/message.gif'></img></LazyLoad>
                                    </div>
                                    
                                    <h3>PRINCIPAL MESSAGE</h3>
                                </div> 
                              
                                    <PrincipalMessage />
                                
                            </div>
                        </div>
                    </div>
                </div>
                <div className="facilities">
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-lg-12 col-md-12'>
                                <div className="title">
                                    <div class="title-gif">
                                        <LazyLoad><img src='https://webapi.entab.info/api/image/STHSM/public/Images/facilities.gif'></img></LazyLoad>
                                    </div>
                                        <h3>SCHOOL FACILITIES</h3>
                                </div>
                            </div>
                            <div className='col-lg-2 col-md-4 col-sm-6 col-xs-12'>
                                <div className="faci-blk animateMe" data-animation="fadeInUpBig" >
                                    <LazyLoad><img src="https://webapi.entab.info/api/image/STHSM/public/Images/smart-class.png" /></LazyLoad>
                                    <p>SMART CLASSES</p>
                                </div>
                            </div>
                            <div className='col-lg-2 col-md-4 col-sm-6 col-xs-12'>
                            <Link to="/library"><div className="faci-blk animateMe" data-animation="fadeInUpBig">
                                    <LazyLoad><img src="https://webapi.entab.info/api/image/STHSM/public/Images/library.png" /></LazyLoad>
                                    <p>SCHOOL LIBRARY</p>
                                </div></Link>
                            </div>
                            <div className='col-lg-2 col-md-4 col-sm-6 col-xs-12'>
                            <Link to="/computer-lab">  <div className="faci-blk animateMe" data-animation="fadeInUpBig">
                                    <LazyLoad><img src="https://webapi.entab.info/api/image/STHSM/public/Images/computer.png" /></LazyLoad>
                                    <p>COMPUTER LAB</p>
                                </div></Link>
                            </div>
                            <div className='col-lg-2 col-md-4 col-sm-6 col-xs-12'>
                                <Link to="/laboratory"> <div className="faci-blk animateMe" data-animation="fadeInUpBig">
                                    <LazyLoad><img src="https://webapi.entab.info/api/image/STHSM/public/Images/chemistry.png" /></LazyLoad>
                                    <p>CHEMISTRY LAB</p>
                                </div> </Link>
                            </div>
                            <div className='col-lg-2 col-md-4 col-sm-6 col-xs-12'>
                             <Link to="/laboratory">  <div className="faci-blk animateMe" data-animation="fadeInUpBig">
                                    <LazyLoad><img src="https://webapi.entab.info/api/image/STHSM/public/Images/bio.png" /></LazyLoad>
                                    <p>BIOLOGY LAB</p>
                                </div> </Link>
                            </div>
                            <div className='col-lg-2 col-md-4 col-sm-6 col-xs-12'>
                            <Link to="/laboratory">  <div className="faci-blk animateMe" data-animation="fadeInUpBig">
                                    <LazyLoad><img src="https://webapi.entab.info/api/image/STHSM/public/Images/physics.png" /></LazyLoad>
                                    <p>PHYSICS LAB</p>
                                </div></Link>
                            </div>
                        </div>
                    </div>                
                </div>
                <div className="school-activities">
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-lg-12 col-md-12'>
                                <div className="title">
                                    <div class="title-gif">
                                        <LazyLoad><img src='https://webapi.entab.info/api/image/STHSM/public/Images/activity.gif'></img></LazyLoad>
                                    </div>
                                        <h3>SCHOOL ACTIVITIES</h3>
                                </div>
                            </div>
                            <div className='col-lg-3 col-md-3 col-sm-12'>
                                <div className="activity-blk animateMe" data-animation="fadeInUpBig">
                                    <h5>School<br /> Parliament</h5>
                                    <LazyLoad><img src="https://webapi.entab.info/api/image/STHSM/public/Images/parliament.png" /></LazyLoad>
                                    <p>To develop leadership qualities among the children, election to the school parliament is held every year. It is aimed at familiarizing children with the process of election, democratic values and knowledge of election procedures. The school captain and vice-captain are elected directly by the children through direct voting in the most democratic way.</p>
                                </div>
                            </div>  
                            <div className='col-lg-3 col-md-3 col-sm-12'>
                                <div className="activity-blk animateMe" data-animation="fadeInUpBig">
                                    <h5>Morning Assembly <br />& Prayer</h5>
                                    <LazyLoad><img src="https://webapi.entab.info/api/image/STHSM/public/Images/prayer.png" /></LazyLoad>
                                    <p>Every day begins with assembly and prayer. This is compulsory for all the children. However physically challenged and sick may be exempted on request.</p>
                                </div>
                            </div>  
                            <div className='col-lg-3 col-md-3 col-sm-12'>
                                <div className="activity-blk animateMe" data-animation="fadeInUpBig">
                                    <h5>Art &<br /> Craft</h5>
                                    <LazyLoad><img src="https://webapi.entab.info/api/image/STHSM/public/Images/art-craft.png" /></LazyLoad>
                                    <p>Through arts and craft, children learn to value and appreciate artifacts and images across cultures and times. Experience in design, art, and crafts enable them to reflect critically on their own work and those by others. They learn to act and think like designers and artists, working intelligently and creatively.</p>
                                </div>
                            </div>  
                            <div className='col-lg-3 col-md-3 col-sm-12'>
                                <div className="activity-blk animateMe" data-animation="fadeInUpBig">
                                    <h5>Clubs &<br /> Association</h5>
                                    <LazyLoad><img src="https://webapi.entab.info/api/image/STHSM/public/Images/club-association.png" /></LazyLoad>
                                    <p>There are various clubs and association active to insight a special love for different subjects. There are various pragrammes and activities held throughout the academic year. Student’s active participation under the guidance of the teachers helps to develop the personalities of the children, in all dimensions.</p>
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>
                <div className='birth-topper'>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-4 col-md-4 animateMe"  data-animation="fadeInLeft">
                                <div className='birthday'>
                                    <div className="title">
                                        <div class="title-gif">
                                            <LazyLoad><img src='https://webapi.entab.info/api/image/STHSM/public/Images/birthday.gif'></img></LazyLoad>
                                        </div>
                                            <h3>BIRTHDAYS</h3>
                                    </div>
                                    <Birthday />
                                </div>
                            </div>
                            <div className="col-lg-8 col-md-8 animateMe"  data-animation="fadeInRight">
                             <div className='topper'>
                                    <div className="title"> 
                                    <div class="title-gif">
                                    <LazyLoad><img src='https://webapi.entab.info/api/image/STHSM/public/Images/trophy.gif'></img></LazyLoad>
                                   </div>
                                            <h3>SCHOOL TOPPERS</h3>
                                    </div> 
                               </div>
                                 <div className="row">
                                     <div className="col-lg-6">
                                        <div className='topper'> 
                                           <Topper />  
                                       </div>
                                     </div>
                                     <div className="col-lg-6">
                                     <div className='topper'>  
                                     <ToppersXII/>
                                    </div>
                                  </div>
                                 </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='gallery'>
                <div className="title">
                        <div class="title-gif">
                            <LazyLoad><img src='https://webapi.entab.info/api/image/STHSM/public/Images/gallery.gif'></img></LazyLoad>
                        </div>
                            <h3>Gallery</h3>
                    </div>
                    <Gallery />
                </div>
                <Footer />
                <Copyright />
            </>
        )
    } 
    export default Home;