
import Header from '../components/Header'
import Footer from '../components/Footer'
import Breadcrumb from "../components/Breadcrumb";
import LazyLoad from 'react-lazyload';
const Syllabus = () => {
    return(
        <>
            <Header/>
            <Breadcrumb pageTitle="Syllabus" mid="About Us" breadcrumbName="Syllabus" />
            <div className='container'>
            <div className='row'>
                    <div className='col-lg-12'>
                        <h3 className="sub-title">Syllabus</h3>
                    </div>
                    <div className='col-lg-4 col-md-4 col-sm-6 col-xs-12'>
                        <div className='syllabus-tab'>
                            <a href="https://webapi.entab.info/api/image/STHSM/public/pdf/SYLLABUS-NURSERY-UKG.pdf" target='_blank'>
                                <LazyLoad><img src="https://webapi.entab.info/api/image/STHSM/public/Images/pdf.png"></img></LazyLoad> <p> Nursery to LKG</p></a>
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-4 col-sm-6 col-xs-12'>
                        <div className='syllabus-tab'>
                            <a href="https://webapi.entab.info/api/image/STHSM/public/pdf/SYLLABUS-I-IV.pdf" target='_blank'>
                             <LazyLoad>   <img src="https://webapi.entab.info/api/image/STHSM/public/Images/pdf.png"></img></LazyLoad> <p> I to IV</p></a>
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-4 col-sm-6 col-xs-12'>
                        <div className='syllabus-tab'>
                            <a href="https://webapi.entab.info/api/image/STHSM/public/pdf/V-VII.pdf" target='_blank'>
                              <LazyLoad>  <img src="https://webapi.entab.info/api/image/STHSM/public/Images/pdf.png"></img></LazyLoad> <p> V to XII</p></a>
                        </div>
                    </div>
                     

                </div>
            </div>
            <Footer />
        </>
    )
}
export default Syllabus