import { useState, useEffect } from "react";
import LazyLoad from "react-lazyload";
import { Link } from 'react-router-dom';
import { getVisitor, postVisitor } from '../Service/Api';

const Footer = () => { 
    const [visitorCount, setVisitorCount] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const incrementVisitor = async () => {
      await postVisitor(); // Trigger the POST request to increment the visitor count
      fetchVisitorCount(); // Fetch the updated visitor count after posting
    };
    
    const fetchVisitorCount = async () => {
      try {
        const data = await getVisitor();
        console.log("Visitor Count", data);
        if (data.length > 0) {
          setVisitorCount(data[0].visitor); // Extract and set the visitor value from the first object in the array
        }
      } catch (error) {
        console.log("Error fetching visitor count:", error);
      } finally {
        setLoading(false);
      }
    };

    incrementVisitor(); // Trigger the POST and GET requests on component mount
  }, []);

  
    
    return(
        <>
        <footer>
            <div className="footer">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <LazyLoad><img src="https://webapi.entab.info/api/image/STHSM/public/Images/Logo.png" /></LazyLoad>
                        </div>
                        <div className="col-lg-2 col-md-6">
                            <div className="quick-link">
                                <h3>Quick Links</h3>
                                <ul>
                                    <li><Link to='/parent-portal'>Portal Help?</Link></li>
                                    <li><Link to='/erp-software'>ERP Software</Link></li>
                                    <li><a href="">Sitemap</a></li>
                                    <li><a href="">Careers</a></li>
                                    <li><a href="">Alumni</a></li>
                                </ul>
                               
                            </div>
                        </div>
                        <div className="col-lg-3  col-md-6">
                            <div className="cont-dtl">
                                <h3>Location</h3>
                                <p><i class="bi bi-geo-alt"></i> Civil Line, Mainpuri,(U.P) - 205001 India</p>
                                <p><i class="bi bi-telephone-fill"></i> <a href="tel: +91-9557352271">+91-9557352271</a>, 
                        <a href="tel: +91-5672297121">+91-5672297121</a>, <br></br><a href="tel: +91-7536040116">+91-7536040116 
                        <LazyLoad><img src="https://webapi.entab.info/api/image/STHSM/public/Images/whatsapp.png" /></LazyLoad></a></p>
                                <p><i class="bi bi-envelope"></i> <a href="mailto:stthomassrschool@gmail.com">stthomassrschool@gmail.com</a></p>
                                <p><i class="bi bi-globe"></i> <a href="http://www.stthomasmainpuri.com/">www.stthomasmainpuri.com</a></p>
                                <p className="visitorCount">Visitor Count: {visitorCount}</p>
                            </div>
                        </div>
                        
                        <div className="col-lg-3 col-md-6">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3547.331496286806!2d79.05837657618314!3d27.24013084594202!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3975965ee3c45ec3%3A0x3c048962016bd11f!2sSt.%20Thomas%20School%2C%20Mainpuri!5e0!3m2!1sen!2sin!4v1738577283488!5m2!1sen!2sin" width="100%" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="d-flex">
                                <div className="app-cont">
                                    <p>Download mobile App</p>
                                    <div>
                                    <a href='https://apps.apple.com/in/app/campuscare-10x/id1611283814' target='_blank'> 
                                    <LazyLoad><img src="https://webapi.entab.info/api/image/STHSM/public/Images/app1.png" /></LazyLoad></a>
                            <a href='https://play.google.com/store/apps/details?id=com.entab.learninglab&hl=en-IN' target='_blank'>
                                 <LazyLoad><img src="https://webapi.entab.info/api/image/STHSM/public/Images/app2.png" /></LazyLoad></a>
                                    </div>
                                    <p className="link">
                                        <a hre="stthomasmainpuri.com">Use School Code :  STSMUP</a>
                                    </p>
                                </div>
                                <div className="phone-img">
                                    <LazyLoad><img src="https://webapi.entab.info/api/image/STHSM/public/Images/app3.png" /></LazyLoad>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                
            </div>
            <LazyLoad><img src="https://webapi.entab.info/api/image/STHSM/public/Images/footer-img.png" className="img-fluid" />  </LazyLoad>
            </footer>
        </>
    )
}
export default Footer;