
import Header from '../components/Header'
import Breadcrumb from "../components/Breadcrumb";
import Footer from '../components/Footer';
import LazyLoad from 'react-lazyload';
const ParentPortal = () => {
    return (
        <>
            <Header />
            <Breadcrumb pageTitle="Parent Portal Guidelines" mid="Parent Portal Guidelines" breadcrumbName="Parent Portal Guidelines" />
            <div className="inner-page">
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12 col-md-12 animateMe' data-animation="fadeInLeft">
                            <div class="inner_cont">
                                <h2 class="mainhead">Parent Portal (Help?)</h2>
                                <h3 class="Subhead"><b>CampusCare10x Application Guidelines &amp; Instructions </b></h3>
                                <p class="guideheading"><strong>Fees payment process</strong></p>
                                <p class="details">
                                    1. Login to the portal <a href="https://entab.online/stsmup" target="_blank">https://entab.online/stsmup</a> using the login credentails
                                </p>
                                <p class="details">
                                    2. Click on Pay Online button on left menu bar
                                </p>
                                <p class="details">
                                    3. Select the Installment and click on Proceed to Pay button
                                </p>
                                <p class="details">
                                    4. Click on Pay now button and select your convenient mode of payment (Debit/Credit cards, Internet Banking, UPI/QR and Wallet).
                                </p>
                                <p class="details">
                                    5. After successful payment, you will be redirected back to parent portal where you can download the free receipts of payment made from Fee section.
                                </p>
                                <div class="clr10"></div>
                                <div>
                                    <p class="guideheading"><strong>Mobile App</strong></p>
                                    <p class="details">
                                        1. Via Mobile App
                                    </p>
                                    <p class="details">
                                        2. Download App
                                    </p>
                                    <p class="details">iOS: CampusCare10X on App Store: - <a href="https://apps.apple.com/in/app/campuscare-10x/id1611283814" target="_blank">
                                        <LazyLoad><img src="https://webapi.entab.info/api/image/STHSM/public/Images/ios-icon.png" width="80px" /></LazyLoad>
                                        </a> <br /> Android: CampusCare10X on Google Play-  <a href="https://play.google.com/store/apps/details?id=com.entab.learninglab" target="_blank">
                                            <LazyLoad><img src="https://webapi.entab.info/api/image/STHSM/public/Images/google-play.png" width="80px" /></LazyLoad>
                                            </a> </p>

                                </div>
                                <p class="guideheading"><strong>Payment Steps: </strong></p>
                                <p class="details">
                                    1. Install and open the CampusCare10X app. Enter the school code: <a href="https://entab.online/stsmup">stsmup</a> .
                                </p>
                                <p class="details">
                                    2. Log in successfully, then tap on the Fees icon.
                                </p>
                                <p class="details">3. Choose the installment and press Proceed to Pay.</p>
                                <p class="details">4. Select Pay now and your payment method.</p>
                                <p class="details">5. Post-payment, the app redirects you for receipt download.</p>
                                <div class="clr10"></div>
                                <div class="assistance">
                                    <h4 class="guideheading">Please call or write for assistance:</h4>
                                    <p><strong> <a href="https://www.entab.in/contact-us.html">PARENT HELP DESK</a></strong> ( 8:00 AM to 8:00 PM ) - <strong> Tele No.</strong> 011- 43193333 (Ext : 5) , E-Mail : <strong><a href="mailto:parentdesk@entab.in" class="yellow-link">parentdesk@entab.in</a></strong></p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default ParentPortal