
import Header from '../components/Header'
import Breadcrumb from "../components/Breadcrumb";
import Footer from '../components/Footer';
import LazyLoad from 'react-lazyload';
const Timing = () => {
    return (
        <>
            <Header />
            <Breadcrumb pageTitle="School Timing " mid="Academics" breadcrumbName="School Timing" />
            <div className="inner-page">
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <h3 className='sub-title'>Summer Timings</h3>
                        </div>
                        <div className='col-lg-4 col-md-4 col-sm-12'>
                            <div className='d-flex timing'>
                                <div className='icon'>
                                    <LazyLoad><img src='https://webapi.entab.info/api/image/STHSM/public/Images/alarm-clock.png' /></LazyLoad>
                                </div>
                                <div className='content'>
                                    <h3>K.G & Nursery</h3>
                                    <p>SUMMER - 7:20 AM to 12:30 PM</p>
                                    <p>WINTER – 8.20 AM TO 01.30 PM</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-4 col-sm-12'>
                            <div className='d-flex timing'>
                                <div className='icon'>
                                    <LazyLoad><img src='https://webapi.entab.info/api/image/STHSM/public/Images/alarm-clock.png' /></LazyLoad>
                                </div>
                                <div className='content'>
                                    <h3>I to III</h3>
                                    <p>SUMMER - 07:20 AM to 01:00 PM</p>
                                    <p>WINTER – 8.20 AM TO 02.00 PM</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-4 col-sm-12'>
                            <div className='d-flex timing'>
                                <div className='icon'>
                                    <LazyLoad><img src='https://webapi.entab.info/api/image/STHSM/public/Images/alarm-clock.png' /></LazyLoad>
                                </div>
                                <div className='content'>
                                    <h3>IV to XII</h3>
                                    <p>SUMMER - 07:20 AM to 01:30 PM</p>
                                    <p>WINTER – 8.20 AM TO 02.30 PM</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-12'>
                            <h3 className='sub-title'>
                            VISITING HOURS
                            </h3>
                            <p><b>PRINCIPAL:</b> <span>SUMMER</span> - 8:30 AM. to 11:00 AM, <span>WINTER</span>  - 9:30 AM. to 12:30 AM</p>
                            {/* <p><b>G.M.:</b> 9:00 a.m. to 12:00 noon (With prior appointment)</p>   
                            <p><b>TEACHERS:</b> 2:00 p.m. to 3:00 p.m. (Wed & Thursday with prior appointment)</p>    */}
                            <p><b>SCHOOL OFFICE TIMINGS:</b>07.10 a.m. TO 2.00 p.m.</p>   
                            {/* <p><b>UNIFORM AND BOOK SHOP TIMINGS:</b> 09:00 a.m. to 01:00 p.m.</p>    */}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default Timing